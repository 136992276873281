@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

header .intro {
  display: table;
  width: 100%;
  padding: 0;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

header .intro .overlay {
  background: rgba(0, 0, 0, 0.2);
}

header .intro-text {
  padding-top: 525px; /* 50% bigger than original 350px */
  padding-bottom: 300px; /* 50% bigger than original 200px */
  text-align: center;
}

.intro .section-title {
  margin-bottom: 30px;
}

.intro .section-title h2 {
  font-size: 33px; /* 50% bigger than original 22px */
  font-weight: 400;
  color: #fff;
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.intro .section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.intro .section-title h1 {
  font-size: 75px; /* 50% bigger than original 50px */
  font-weight: 700;
  color: #fff;
}

/* Adjust for mobile responsiveness */
@media (max-width: 768px) {
  header .intro-text {
    padding-top: 300px;
    padding-bottom: 200px;
  }
  
  .intro .section-title h1 {
    font-size: 45px;
  }
  
  .intro .section-title h2 {
    font-size: 24px;
  }
}
